<template>
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
            <g fill="#FFF" opacity=".6">
                <path d="M.5 6h5c.276 0 .5-.223.5-.5v-5c0-.276-.224-.5-.5-.5h-5C.224 0 0 .224 0 .5v5c0 .277.224.5.5.5zM1 1h4v4H1V1zM8.5 6h5c.276 0 .5-.223.5-.5v-5c0-.276-.224-.5-.5-.5h-5c-.276 0-.5.224-.5.5v5c0 .277.224.5.5.5zM9 1h4v4H9V1zM21.5 0h-5c-.276 0-.5.224-.5.5v5c0 .277.224.5.5.5h5c.276 0 .5-.223.5-.5v-5c0-.276-.224-.5-.5-.5zM21 5h-4V1h4v4zM.5 14h5c.276 0 .5-.223.5-.5v-5c0-.277-.224-.5-.5-.5h-5c-.276 0-.5.223-.5.5v5c0 .277.224.5.5.5zM1 9h4v4H1V9zM8 13.5c0 .277.224.5.5.5h5c.276 0 .5-.223.5-.5v-5c0-.277-.224-.5-.5-.5h-5c-.276 0-.5.223-.5.5v5zM9 9h4v4H9V9zM21.5 8h-5c-.276 0-.5.223-.5.5v5c0 .277.224.5.5.5h5c.276 0 .5-.223.5-.5v-5c0-.277-.224-.5-.5-.5zm-.5 5h-4V9h4v4zM.5 22h5c.276 0 .5-.224.5-.5v-5c0-.276-.224-.5-.5-.5h-5c-.276 0-.5.224-.5.5v5c0 .276.224.5.5.5zm.5-5h4v4H1v-4zM8 21.5c0 .276.224.5.5.5h5c.276 0 .5-.224.5-.5v-5c0-.276-.224-.5-.5-.5h-5c-.276 0-.5.224-.5.5v5zM9 17h4v4H9v-4zM21.5 16h-5c-.276 0-.5.224-.5.5v5c0 .276.224.5.5.5h5c.276 0 .5-.224.5-.5v-5c0-.276-.224-.5-.5-.5zm-.5 5h-4v-4h4v4z"/>
            </g>
        </svg>
    </div>
</template>
<script>
export default {
    name: "ui-tiles"
}
</script>
