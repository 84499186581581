<template>
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20">
            <g fill="#FFF" opacity=".6">
                <path d="M.5 20c.276 0 .5-.224.5-.5V.5C1 .224.776 0 .5 0S0 .224 0 .5v19c0 .276.224.5.5.5zM4.146 4.854c.196.195.512.195.708 0 .195-.196.195-.512 0-.708l-2-2c-.143-.143-.358-.186-.546-.108-.186.077-.308.26-.308.462v15c0 .202.122.385.308.462.063.026.128.038.192.038.13 0 .258-.051.353-.146l2-2c.196-.196.196-.512 0-.707-.195-.196-.511-.196-.707 0L3 16.293V3.707l1.146 1.147zM17.854 15.146c-.196-.195-.512-.195-.707 0-.196.196-.196.512 0 .707l2 2c.095.096.223.147.353.147.064 0 .13-.012.192-.038.186-.077.308-.26.308-.462v-15c0-.202-.122-.385-.308-.462-.188-.078-.402-.034-.546.109l-2 2c-.195.195-.195.511 0 .707.196.195.512.195.707 0L19 3.707v12.586l-1.146-1.146zM21.5 0c-.276 0-.5.224-.5.5v19c0 .276.224.5.5.5s.5-.224.5-.5V.5c0-.276-.224-.5-.5-.5zM6.5 5c-.276 0-.5.223-.5.5v9c0 .277.224.5.5.5h9c.276 0 .5-.223.5-.5v-9c0-.277-.224-.5-.5-.5h-9zm8.5 9H7V6h8v8z"/>
            </g>
        </svg>
    </div>
</template>
<script>
export default {
    name: "ui-cover"
}
</script>
