<template>
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21">
            <g fill="#FFF" opacity=".6">
                <path d="M5.5 0h-5C.224 0 0 .224 0 .5v4c0 .277.224.5.5.5h5c.277 0 .5-.223.5-.5v-4c0-.276-.223-.5-.5-.5zM5 4H1V1h4v3zM5.5 8h-5c-.276 0-.5.223-.5.5v4c0 .277.224.5.5.5h5c.277 0 .5-.223.5-.5v-4c0-.277-.223-.5-.5-.5zM5 12H1V9h4v3zM5.5 16h-5c-.276 0-.5.224-.5.5v4c0 .276.224.5.5.5h5c.277 0 .5-.224.5-.5v-4c0-.276-.223-.5-.5-.5zM5 20H1v-3h4v3zM21.5 0h-13c-.277 0-.5.224-.5.5s.223.5.5.5h13c.276 0 .5-.224.5-.5s-.224-.5-.5-.5zM21.5 2h-13c-.277 0-.5.224-.5.5s.223.5.5.5h13c.276 0 .5-.224.5-.5s-.224-.5-.5-.5zM8.5 5h10c.276 0 .5-.223.5-.5s-.224-.5-.5-.5h-10c-.277 0-.5.223-.5.5s.223.5.5.5zM21.5 8h-13c-.277 0-.5.223-.5.5s.223.5.5.5h13c.276 0 .5-.223.5-.5s-.224-.5-.5-.5zM21.5 10h-13c-.277 0-.5.223-.5.5s.223.5.5.5h13c.276 0 .5-.223.5-.5s-.224-.5-.5-.5zM8.5 13h10c.276 0 .5-.223.5-.5s-.224-.5-.5-.5h-10c-.277 0-.5.223-.5.5s.223.5.5.5zM21.5 16h-13c-.277 0-.5.224-.5.5s.223.5.5.5h13c.276 0 .5-.224.5-.5s-.224-.5-.5-.5zM21.5 18h-13c-.277 0-.5.224-.5.5s.223.5.5.5h13c.276 0 .5-.224.5-.5s-.224-.5-.5-.5zM18.5 20h-10c-.277 0-.5.224-.5.5s.223.5.5.5h10c.276 0 .5-.224.5-.5s-.224-.5-.5-.5z"/>
            </g>
        </svg>
    </div>
</template>
<script>
export default {
    name: "gen-list"
}
</script>

